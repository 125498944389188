<template>
    <a-modal centered :title="isEdit ? '编辑设备' : '添加设备'" :width="640" :maskClosable="false" :visible="visible" :confirmLoading="confirmLoading" @ok="handleSubmit" @cancel="handleCancel">
        <a-spin :spinning="confirmLoading">
            <a-form :form="form">
                <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入名称" v-decorator="['name', { rules: [{required: true, min: 2, message: '名称最少2个字符'}]}]" />
                </a-form-item>
                <a-form-item label="设备类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select
                            placeholder="请选择设备类型"
                            v-decorator="['type', { rules: [{required: true, message: '请选择设备类型'}]}]"
                    >
                        <a-select-option v-for="item in deviceTypeList" :key="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="所属水厂" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select
                            @change="handleWaterfactoryChange"
                            placeholder="请选择所属水厂"
                            v-decorator="['wfid', { rules: [{required: true, message: '请选择所属水厂'}]}]"
                    >
                        <a-select-option v-for="item in waterfactorys" :key="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="所属监测点" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select
                            @change="handleMonitorChange"
                            placeholder="请选择所属监测点"
                            v-decorator="['mid', { rules: [{required: false, message: '请选择所属监测点'}]}]"
                    >
                        <a-select-option v-for="item in monitors" :key="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="所属管井" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select
                            @change="handleGroupChange"
                            placeholder="请选择所属管井"
                            v-decorator="['groupid', { rules: [{required: false, message: '请选择所属管井'}]}]"
                    >
                        <a-select-option v-for="item in groups" :key="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="经度" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input-number style="width: 100%;" placeholder="请输入经度" v-decorator="['lon', { rules: [{required: false, message: '请输入经度'}]}]" />
                </a-form-item>
                <a-form-item label="纬度" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input-number style="width: 100%;" placeholder="请输入纬度" v-decorator="['lat', { rules: [{required: false, message: '请输入纬度'}]}]" />
                </a-form-item>
                <a-form-item label="设备编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入设备编号" v-decorator="['originid', { rules: [{required: false, message: '请输入设备编号'}]}]" />
                </a-form-item>
                <a-form-item label="监测点中编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input-number style="width: 100%;" placeholder="请输入监测点中编号" v-decorator="['idinmonitor', { rules: [{required: false, message: '请输入监测点中编号'}]}]" />
                </a-form-item>
                <a-form-item label="数据上报间隔(秒)" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input-number style="width: 100%;" placeholder="请输入数据上报间隔" v-decorator="['intval', { rules: [{required: false, message: '请输入数据上报间隔'}]}]" />
                </a-form-item>
                <a-form-item label="报警需波动次数" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input-number style="width: 100%;" placeholder="请输入报警需波动次数" v-decorator="['warningN', { rules: [{required: false, message: '请输入报警需波动次数'}]}]" />
                </a-form-item>
                <a-form-item label="有效范围下限" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input-number style="width: 100%;" placeholder="请输入有效范围下限" v-decorator="['thresholdDown', { rules: [{required: false, message: '请输入有效范围下限'}]}]" />
                </a-form-item>
                <a-form-item label="有效范围上限" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input-number style="width: 100%;" placeholder="请输入有效范围上限" v-decorator="['thresholdUp', { rules: [{required: false, message: '请输入有效范围上限'}]}]" />
                </a-form-item>
                <a-form-item label="安装时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-date-picker style="width: 100%;" placeholder="请选择安装时间" v-decorator="['equipTime', { rules: [{required: false, message: '请选择安装时间'}]}]" />
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>

<script>
  import { getAreaParam,groupList,monitorList,findWaterfactoryByRegion,addDevice, updateDevice } from "@/api/monitor/index"
  import moment from 'moment'
  import pick from 'lodash.pick'
  export default {
    data() {
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        },
        visible: false,
        confirmLoading: false,
        isEdit: false,
        id: null,
        record: null,
        form: this.$form.createForm(this),
        waterfactorys: [],
        monitors: [],
        groups: [],
        deviceTypeList: []
      }
    },
    created() {
        let areaParam = getAreaParam()
      findWaterfactoryByRegion(areaParam).then(r=>{
        this.waterfactorys.splice(0)
        this.waterfactorys = r.data
      })
    },
    methods: {
      add(deviceTypeList) {
        this.deviceTypeList = deviceTypeList
        this.visible = true
        this.isEdit = false
        this.form.resetFields()
      },
      edit(record,deviceTypeList) {
        this.deviceTypeList = deviceTypeList
        this.id = record.id
        this.record = Object.assign({},record)
        if(this.record.equipTime){
          this.record.equipTime = moment(this.record.equipTime)
        }
        if(record.mid > 0){
          monitorList({wfid:record.wfid}).then(r=>{
            this.monitors.splice(0)
            this.monitors = r.data
            this.monitors.unshift({id:0,name:'无'})
          })
          if(record.groupid > 0){
            groupList({mid:record.mid}).then(r=>{
              this.groups.splice(0)
              this.groups = r.data
              this.groups.unshift({id:0,name:'无'})
            })
          }else{
            this.groups.splice(0)
            this.groups.unshift({id:0,name:'无'})
          }
        }else{
          this.monitors.splice(0)
          this.groups.splice(0)
          this.monitors.unshift({id:0,name:'无'})
          this.groups.unshift({id:0,name:'无'})
        }
        this.visible = true
        this.isEdit = true
        console.log('edit');
        console.log(this.record);
        const { form: { setFieldsValue } } = this
        this.form.resetFields()
        this.$nextTick(() => {
          setFieldsValue(pick(record, ['name', 'lon', 'lat','wfid','mid','groupid','type','originid','idinmonitor','intval','warningN','thresholdDown','thresholdUp','equipTime']))
        })
      },
      handleWaterfactoryChange(value){
        this.form.setFieldsValue({mid:undefined})
        this.form.setFieldsValue({groupid:undefined})
        monitorList({wfid:value}).then(r=>{
          this.monitors.splice(0)
          this.monitors = r.data
          this.monitors.unshift({id:0,name:'无'})
        })
      },
      handleMonitorChange(value){
        this.form.setFieldsValue({groupid:undefined})
        if(value > 0){
          groupList({mid:value}).then(r=>{
            this.groups.splice(0)
            this.groups = r.data
            this.groups.unshift({id:0,name:'无'})
          })
        }else{
          this.groups.splice(0)
          this.groups.unshift({id:0,name:'无'})
        }
      },
      handleGroupChange(value){

      },
      handleSubmit() {
        const { form: { validateFields } } = this
        this.confirmLoading = true
        validateFields((errors, values) => {
          if (!errors) {
            if (this.isEdit) {
              const param = Object.assign({id:this.id},values)
              if(param.equipTime){
                param.equipTime = moment(param.equipTime).format("YYYY-MM-DD")
              }
              updateDevice(param).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.$emit('ok', values)
                  this.visible = false
                }else{
                  this.$message.warn(r.msg)
                }
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            } else {
              const param = Object.assign({},values)
              if(param.equipTime){
                param.equipTime = moment(param.equipTime).format("YYYY-MM-DD")
              }
              addDevice(param).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.visible = false
                  this.$emit('ok', values)
                }else{
                  this.$message.warn(r.msg)
                }
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            }
          } else {
            this.confirmLoading = false
          }
        })
      },
      handleCancel() {
        this.visible = false
      },
    }
  }
</script>
