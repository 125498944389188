<template>
    <div>
        <a-card title="设备列表">
            <div class="table-page-search-wrapper">
                <a-form :form="form" layout="inline">
                    <a-row :gutter="[24, 0]">
                        <a-col :xxl="16" :sm="24">
                            <a-form-item label="所属区域：">
                                <a-select
                                        style="width: 120px;margin-right: 10px;"
                                        placeholder="请选择"
                                        v-model="queryParam.cityId"
                                        v-if="level <= 2"
                                        :disabled="level == 2"
                                >
                                    <a-select-option v-for="item in citys" :key="item.id">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                                <a-select
                                        style="width: 150px;margin-right: 10px;"
                                        placeholder="请选择"
                                        v-model="queryParam.areaId"
                                        v-if="level <= 3"
                                >
                                    <a-select-option v-for="item in areas" :key="item.id">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                                <a-select
                                        style="width: 150px;margin-right: 10px;"
                                        placeholder="请选择"
                                        v-model="queryParam.wfid"
                                >
                                    <a-select-option v-for="item in waterfactorys" :key="item.id">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                                <a-select
                                        style="width: 200px;margin-right: 10px;"
                                        placeholder="请选择"
                                        v-model="queryParam.mid"
                                >
                                    <a-select-option v-for="item in monitors" :key="item.id">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xxl="8" :sm="24">
                            <a-form-item label="设备类型：">
                                <a-select
                                        style=""
                                        placeholder="请选择"
                                        v-model="queryParam.type"
                                >
                                    <a-select-option v-for="item in deviceTypeList" :key="item.id">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xxl="8" :sm="24">
                            <a-form-item no-prop label="设备状态：">
                                <a-select
                                        style=""
                                        placeholder="请选择"
                                        v-model="queryParam.status"
                                >
                                    <a-select-option v-for="item in statusTags" :key="item.id">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xxl="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery">查询</a-button>
                  <!--<a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>-->
              </span>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
            <!--<div class="filter-line ">-->
                <!--<span>所属区域：</span>-->
                <!--<a-select-->
                        <!--style="width: 120px;margin-right: 10px;"-->
                        <!--placeholder="请选择"-->
                        <!--v-model="queryParam.cityId"-->
                        <!--v-if="level <= 2"-->
                        <!--:disabled="level == 2"-->
                <!--&gt;-->
                    <!--<a-select-option v-for="item in citys" :key="item.id">-->
                        <!--{{ item.name }}-->
                    <!--</a-select-option>-->
                <!--</a-select>-->
                <!--<a-select-->
                        <!--style="width: 150px;margin-right: 10px;"-->
                        <!--placeholder="请选择"-->
                        <!--v-model="queryParam.areaId"-->
                        <!--v-if="level <= 3"-->
                <!--&gt;-->
                    <!--<a-select-option v-for="item in areas" :key="item.id">-->
                        <!--{{ item.name }}-->
                    <!--</a-select-option>-->
                <!--</a-select>-->
                <!--<a-select-->
                        <!--style="width: 150px;margin-right: 10px;"-->
                        <!--placeholder="请选择"-->
                        <!--v-model="queryParam.wfid"-->
                <!--&gt;-->
                    <!--<a-select-option v-for="item in waterfactorys" :key="item.id">-->
                        <!--{{ item.name }}-->
                    <!--</a-select-option>-->
                <!--</a-select>-->
                <!--<a-select-->
                        <!--style="width: 200px;margin-right: 10px;"-->
                        <!--placeholder="请选择"-->
                        <!--v-model="queryParam.mid"-->
                <!--&gt;-->
                    <!--<a-select-option v-for="item in monitors" :key="item.id">-->
                        <!--{{ item.name }}-->
                    <!--</a-select-option>-->
                <!--</a-select>-->
            <!--</div>-->
            <!--<div class="filter-line ">-->
                <!--<span>设备类型：</span>-->
                <!--<a-select-->
                        <!--style="width: 150px;"-->
                        <!--placeholder="请选择"-->
                        <!--v-model="queryParam.type"-->
                <!--&gt;-->
                    <!--<a-select-option v-for="item in deviceTypeList" :key="item.id">-->
                        <!--{{ item.name }}-->
                    <!--</a-select-option>-->
                <!--</a-select>-->
                <!--&lt;!&ndash;<span><tag-select v-if="deviceTypeList.length > 0" ref="typeTag" @change="onTypeChange"><tag-select-option v-for="item in deviceTypeList" :value="item.id" >{{item.name}}</tag-select-option></tag-select></span>&ndash;&gt;-->
            <!--</div>-->
            <!--<div class="filter-line">-->
                <!--<span>设备状态：</span>-->
                <!--<a-select-->
                        <!--style="width: 150px;"-->
                        <!--placeholder="请选择"-->
                        <!--v-model="queryParam.status"-->
                <!--&gt;-->
                    <!--<a-select-option v-for="item in statusTags" :key="item.id">-->
                        <!--{{ item.name }}-->
                    <!--</a-select-option>-->
                <!--</a-select>-->
                <!--&lt;!&ndash;<span>&ndash;&gt;-->
                    <!--&lt;!&ndash;<tag-select ref="statusTag" @change="onStatusChange"><tag-select-option v-for="item in statusTags" :value="item.id" >{{item.name}}</tag-select-option></tag-select>&ndash;&gt;-->
                <!--&lt;!&ndash;</span>&ndash;&gt;-->
            <!--</div>-->
        </a-card>
        <a-card title="查询结果" style="margin-top: 5px;">
            <div slot="extra">
                <!--<a-button @click="handleDownload" type="default" icon="" style="">地图浏览</a-button>-->
                <a-button
                        type="primary"
                        style="margin-left:10px;"
                        icon="plus"
                        @click="showAddDeviceModal"
                >
                    添加设备
                </a-button>
                <a-button @click="exportReport" type="primary" icon="" style="margin-left: 10px;">导出数据</a-button>
            </div>
            <div style="height: 120px;font-size: 16px;">
                <div>
                  <a-row>
                      <a-col :xs="4">
                          <div style="height: 120px;width:200px;" ref="chart2"></div>
                      </a-col>
                      <a-col :xs="5">
                          <div style="display: flex;align-items: center;height: 120px;">
                              <span>设备总数</span>
                              <span style="margin-left: 15px;font-weight: bold;font-size: 18px;">{{extra.total}}</span>
                          </div>
                      </a-col>
                      <a-col :xs="5">
                          <div style="display: flex;align-items: center;height: 120px;">
                              <a-tag color="green">
                                  在线
                              </a-tag>
                              <span style="margin-left: 15px;font-weight: bold;font-size: 18px;">{{extra.online}}</span>
                              <span style="margin-left: 35px;font-size: 16px;" v-if="">{{extra.onlineP}}%</span>
                          </div>
                      </a-col>
                      <a-col :xs="5">
                          <div style="display: flex;align-items: center;height: 120px;">
                              <a-tag color="yellow">
                                  维护
                              </a-tag>
                              <span style="margin-left: 15px;font-weight: bold;font-size: 18px;">{{extra.maintenance}}</span>
                              <span style="margin-left: 35px;font-size: 16px;" v-if="">{{extra.maintenanceP}}%</span>
                          </div>
                      </a-col>
                      <a-col :xs="5">
                          <div style="display: flex;align-items: center;height: 120px;">
                              <a-tag color="red">
                                  故障
                              </a-tag>
                              <span style="margin-left: 15px;font-weight: bold;font-size: 18px;">{{extra.fault}}</span>
                              <span style="margin-left: 35px;font-size: 16px;" v-if="">{{extra.faultP}}%</span>
                          </div>
                      </a-col>
                  </a-row>
                </div>
            </div>

            <a-table
                    row-key="id"
                    :columns="columns"
                    :dataSource="data"
                    :pagination="pagination"
                    @change="handleTableChange"
                    :loading="confirmLoading"
            >
                <a slot="name" slot-scope="text, record" @click="edit(record)">{{ text }}</a>
                <template slot="index" slot-scope="text, record">
                    <div>{{text}}</div>
                </template>
                <template slot="status" slot-scope="text, record">
                    <a-tag v-if="record.status === 1" color="green">
                        在线
                    </a-tag>
                    <a-tag v-else-if="record.status === 2" color="yellow">
                        维护
                    </a-tag>
                    <a-tag v-else color="red">
                        故障
                    </a-tag>
                </template>
                <template slot="lonlat" slot-scope="text, record">
                    <div>{{ record.lon ? (record.lon + ' , ' + record.lat) : '-' }}</div>
                </template>
                <template slot="type" slot-scope="text, record">
                    <div>{{ getDeviceTypeName(record.type) }}</div>
                </template>
                <template slot="zhibiao" slot-scope="text, record">
                    <div>{{ getZhibiaoName(record.type) }}</div>
                </template>
                <template slot="groupid" slot-scope="text, record">
                    <div>{{ getGroupName(record.groupid) }}</div>
                </template>
                <template slot="mid" slot-scope="text, record">
                    <div>{{ getMonitorName(record.mid) }}</div>
                </template>
                <template slot="wfid" slot-scope="text, record">
                    <div>{{ getWaterfactoryName(record.mid) }}</div>
                </template>
                <template slot="netType" slot-scope="text, record">
                    <div>{{text | netTypeFilter}}</div>
                </template>
                <template slot="link" slot-scope="text, record">
                    <div>{{text | linkFilter}}</div>
                </template>
                <template slot="remark" slot-scope="text">
                    <a-tooltip v-if="text">
                        <template slot="title">
                            {{ text }}
                        </template>
                        <a-icon type="message" :style="{opacity: text ? 1 : 0.3,fontSize: '20px'}"/>
                    </a-tooltip>
                    <a-icon v-else type="message" :style="{opacity: text ? 1 : 0.3,fontSize: '20px'}"/>
                </template>
                <span slot="action" slot-scope="text, record">
              <template>
                   <div style="display: flex;justify-content: space-around;">
                       <a-button
                               v-if="record.type == 6"
                               type="primary"
                               size="small"
                               @click="handleStart(record)"
                       >
                  <a-icon type="play-circle" theme="filled" />
                </a-button>
                <a-button
                        v-if="record.type == 6"
                        type="danger"
                        size="small"
                        @click="handleStop(record)"
                >
                  <a-icon type="pause-circle" theme="filled" />
                </a-button>
                <a-button
                        type="primary"
                        size="small"
                        @click="handleEdit(record)"
                >
                  <a-icon type="edit" theme="filled" />
                </a-button>
                <a-button
                        type="danger"
                        size="small"
                        @click="handleDel(record)"
                >
                  <a-icon type="delete" theme="filled" />
                </a-button>
                   </div>
              </template>
            </span>
                <span slot="range" slot-scope="text, record">
            <trend :flag="record.status === 0 ? 'up' : 'down'">
              {{ text }}%
            </trend>
          </span>
            </a-table>
        </a-card>
        <device-modal @ok="handleOk" ref="deviceModal"></device-modal>
        <a-modal
                title="请输入密码"
                :visible="visible"
                @ok="handlePwdModalOk"
                @cancel="handlePwdModalCancel"
                ok-text="确认"
                cancel-text="取消"
        >
            <a-input-password id='startStopPwd' v-model="password" placeholder="请输入密码" autocomplete="off"/>
        </a-modal>
    </div>
</template>

<script>
  import './monitor_pub.less'
  import moment from 'moment'
  import * as echarts from "echarts";
  import {TagSelect} from '@/components'
  import { deleteDevice,getRegion,findRegionById,findRegionByPid,getAreaParam,devicePage,deviceExport,deviceTypeAll,zhibiaoAll,monitorAll,monitorList,groupAll,waterfactoryAll,findWaterfactoryByRegion } from "@/api/monitor/index";
  import TagSelectOption from "../../components/TagSelect/TagSelectOption";
  import { export_json_to_excel } from '@/excel/Export2Excel'
  import DeviceModal from "./component/DeviceModal"

  const publicColums = [
    {
      dataIndex: 'index',
      title: '序号',
      align: 'center',
      scopedSlots: { customRender: 'index' }
    },
    {
      dataIndex: 'status',
      title: '状态',
      align: 'center',
      scopedSlots: { customRender: 'status' },
      width : 80
    },
    {
      dataIndex: 'name',
      title: '所在管网',
      align: 'center'
    },
    {
      dataIndex: 'originid',
      title: '设备编码',
      align: 'center',
      width : 130
    },
    {
      dataIndex: 'type',
      title: '设备名称',
      align: 'center',
      scopedSlots: { customRender: 'type' },
      sorter: true
    },
    {
      dataIndex: 'wfid',
      title: '水厂',
      align: 'center',
      scopedSlots: { customRender: 'wfid' }
    },
    {// 5
      dataIndex: 'mid',
      title: '监测点',
      align: 'center',
      scopedSlots: { customRender: 'mid' }
    },
    {
      dataIndex: 'link',
      title: '环节',
      align: 'center',
      scopedSlots: { customRender: 'link' }
    },
    {
      dataIndex: 'groupid',
      title: '监测管井',
      align: 'center',
      scopedSlots: { customRender: 'groupid' }
    },
    {
      dataIndex: 'netType',
      title: '管网类型',
      align: 'center',
      scopedSlots: { customRender: 'netType' }
    },
    {
      dataIndex: 'zhibiaos',
      title: '监测指标',
      align: 'center'
    },
    {
      dataIndex: 'lonlat',
      title: '经纬度',
      align: 'center',
      scopedSlots: { customRender: 'lonlat' }
    },
    {
      dataIndex: 'equipTime',
      title: '安装时间',
      align: 'center'
    },
    {// 10
      dataIndex: 'action',
      title: '操作',
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ]

  export default {
    name: "device",
    components: {
      TagSelectOption,
      TagSelect,
      DeviceModal
    },
    data(){
      return {
        visible: false,
        password: '',
        columns: publicColums,
        level: 1,
        data: [],
        deviceTypeList: [],
        zhibiaoList: [],
        monitorList: [],
        groupList: [],
        citys:[{id:0,name:'全部市'}],
        areas:[{id:0,name:'全部区'}],
        waterfactorys: [{id:0,name:'全部水厂'}],
        monitors: [{id:0,name:'全部监测点'}],
        waterfactoryList: [],
        confirmLoading: false,
        form: this.$form.createForm(this),
        pagination: {
          size: '',
          current: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: true,
          showLessItems: true,
          pageSizeOptions: ['5', '10', '20', '30', '50'],
          onShowSizeChange: (current, pageSize) => { this.pageSize = pageSize },
          showTotal: total => `共${total}条`
        },
        queryParam: { pageNum: 1, pageSize: 10, orderBy: '', type: 0, status: 0,types:[],statuses:[],cityId:0,areaId:0,wfid:0,mid:0 },
        extra: {},
        statusTags:[{id:0,name:'全部'},{id:1,name:'在线',checked:false},{id:2,name:'维护',checked:false},{id:3,name:'故障',checked:false}],
        selectedStatus:[],
        selectedTypes: [],

      }
    },
    filters: {
      netTypeFilter (netType) {
        return netType == 1 ? '主线管网' : '支线管网'
      },
      linkFilter(link){
        return !link  ? '-' : link == 1 ? '水源' :  link == 2 ? '水厂' : link ==  3 ? '输配水管网': link == 4 ? '末梢' : ''
      }
    },
    created() {
      let areaParam = getAreaParam()
      this.level = areaParam.level
      let region = getRegion(this.level)
      switch (this.level) {
        case 2:
          this.citys = [region]
          this.queryParam.cityId = region.id
          break
        case 3:
          this.areas = [region]
          this.queryParam.areaId = region.id
          break
      }

      for (let i = 0; i < this.statusTags.length; i++) {
        this.selectedStatus.push(this.statusTags[i].id)
      }
      waterfactoryAll().then(r=>{
        this.waterfactoryList = r.data
      })
      monitorAll().then(r=>{
        this.monitorList = r.data
      })
      groupAll().then(r=>{
        this.groupList = r.data
      })
      zhibiaoAll().then(r=>{
        this.zhibiaoList = r.data
      })
      deviceTypeAll().then(r=>{
        this.deviceTypeList = r.data
        this.deviceTypeList.unshift({id:0,name:'全部'})
        for (let i = 0; i < this.deviceTypeList.length; i++) {
          this.selectedTypes.push(this.deviceTypeList[i].id)
        }
        // this.$nextTick(() =>{
        //   this.$refs['typeTag'].onCheckAll({checked:true})
        // })

      })
      this.fetchList()
    },
    mounted(){
        // this.$refs['statusTag'].onCheckAll({checked:true})

    },
    watch:{
      'queryParam.cityId'(val){
        this.areas.splice(1)
        findRegionByPid({pid:val}).then(r=>{
          this.areas.push(...r.data)
        })
      },
      'queryParam.areaId'(val){
        this.waterfactorys.splice(1)
        findWaterfactoryByRegion({areaId:val}).then(r=>{
          this.waterfactorys.push(...r.data)
        })
      },
      'queryParam.wfid'(val){
        this.monitors.splice(1)
        monitorList({wfid:val}).then(r=>{
          this.monitors.push(...r.data)
        })
      },
      selectedTypes(val){
        console.log('selectedTypes',val)
      }
    },
    methods:{
      fetchList () {
        this.confirmLoading = true
        devicePage(this.queryParam).then(r => {
          r = r.data
          console.log('r',r)
          const list = r.list || []
          const extra = r.extra
          if(extra){
            const total = extra.total
            const online = extra.online
            const maintenance = extra.maintenance
            const fault = extra.fault
            if(total === 0){
              extra.onlineP = 0
              extra.maintenanceP = 0
              extra.faultP = 0
            }else{
              extra.onlineP = (online * 100/total).toFixed(2)
              extra.maintenanceP = (maintenance * 100/total).toFixed(2)
              extra.faultP = (fault * 100/total).toFixed(2)
            }
          }
          this.extra = extra || {}

          const len = list.length
          for (let i = 0; i < len; i++) {
            list[i].index = i + 1
            list[i].zhibiaos = list[i].zhibiaos.join('，')
            list[i].equipTime = moment(list[i].equipTime).format('YYYY-MM-DD')
          }

          this.data = list
          this.pagination.current = r.pageNum
          this.pagination.total = r.total
          this.pagination.pageSize = r.pageSize

          this.initChart2(this.extra)
        }).finally(() => {
          this.confirmLoading = false
        })
     },
      handleStart(record){
        this.visible = true
      },
      handleStop(record){
        this.visible = true
      },
      handlePwdModalOk(){
        if(this.password.length == 0){
          this.$message.info('密码不能为空')
          return
        }
        this.visible = false
      },
      handlePwdModalCancel(){
        this.visible = false
      },
      showAddDeviceModal(){
        let arr = []
        for (let i = 0; i < this.deviceTypeList.length; i++) {
          let deviceType = this.deviceTypeList[i]
          if(deviceType.id > 0){
            arr.push(deviceType)
          }
        }
        this.$refs.deviceModal.add(arr)
      },
      handleEdit(record){
        let arr = []
        for (let i = 0; i < this.deviceTypeList.length; i++) {
          let deviceType = this.deviceTypeList[i]
          if(deviceType.id > 0){
            arr.push(deviceType)
          }
        }
        this.$refs.deviceModal.edit(record,arr)
      },
      handleOk(values){
        this.$message.info(values.isEdit ? '修改成功' : '添加成功')
        this.fetchList()
      },
      handleDel(record){
        const self = this
        this.$confirm({
          title: '确认删除',
          content: `是否确认删除当前设备？`,
          cancelText: '取消',
          okText: '确定',
          okType: 'danger',
          onOk () {
            return new Promise((resolve, reject) => {
              deleteDevice({ id: record.id }).then(r => {
                if(r.code === 0){
                  self.$message.info('删除成功')
                  self.fetchList()
                }else{
                  self.$message.warn(r.msg)
                }
              }).catch((e) => {
              }).finally(() => {
                resolve()
              })
            })
          }
        })
      },
      handleQuery() {
        this.queryParam.pageNum = 1
        this.fetchList();
      },
      handleChange(tag, checked) {
        const { selectedStatus } = this;
        const nextSelectedTags = checked ? [...selectedStatus, tag] : selectedStatus.filter(t => t !== tag);
        console.log('You are interested in: ', nextSelectedTags);
        this.selectedStatus = nextSelectedTags;
      },
      initChart2(deviceStatusNum){
        var chartDom = this.$refs.chart2;
        var myChart = echarts.init(chartDom);
        var option;
        option =  {
          tooltip: {
            trigger: 'item'
          },
        grid:{
          top:0,
          bottom:0
        },
          // legend: {
          //   left: '30%',  //图例距离左的距离
          //   y: 'center',  //图例上下居中
          // },
          color:[ //自定义的颜色
            'rgb(77,203,115)',
            'rgb(250,211,55)',
            'rgb(240,72,100)'
          ],
          series: [
            {
              name: '',
              type: 'pie',
              center: ['50%', '50%'],
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '14',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                {value: deviceStatusNum.online, name: '正常'},
                {value: deviceStatusNum.maintenance, name: '维护'},
                {value: deviceStatusNum.fault, name: '故障'},
              ]
            }
          ]
        };
        myChart.setOption(option);
      },
      onTypeChange(checked){
        console.log('onTypeChange',checked)
        const value = checked.value
        const b = checked.checked
        if(value === 'total'){
          if(b){
            for (let i = 0; i < this.deviceTypeList.length; i++) {
              if(this.selectedTypes.indexOf(this.deviceTypeList[i].id) === -1){
                this.selectedTypes.push(this.deviceTypeList[i].id)
              }
            }
          }else{
            this.selectedTypes.length = 0
          }
        }else{
          if(b){
            if(this.selectedTypes.indexOf(value) === -1){
              this.selectedTypes.push(value)
            }
          }else{
            let index = this.selectedTypes.indexOf(value)
            if(index !== -1){
              this.selectedTypes.splice(index,1)
            }
          }
        }
        console.log('selectedTypes',this.selectedTypes)
        const len = this.selectedTypes.length
        if(len === 0){
          this.queryParam.type = 0
          this.queryParam.types = []
        }else if(len === 1){
          if(this.selectedTypes[0] === 'total'){
            this.queryParam.type = 0
            this.queryParam.types = []
          }else{
            this.queryParam.type = this.selectedTypes[0]
          }
        }else{
          let arr = []
          for (let i = 0; i < len; i++) {
            if(this.selectedTypes[i] !== 'total'){
              arr.push(this.selectedTypes[i])
            }
          }
          this.queryParam.type = 0
          this.queryParam.types = arr
        }
        this.fetchList()
      },
      onStatusChange(checked){
        console.log('onStatusChange',checked)
        const value = checked.value
        const b = checked.checked
        if(value === 'total'){
          if(b){
            for (let i = 0; i < this.statusTags.length; i++) {
              if(this.selectedStatus.indexOf(this.statusTags[i].id) === -1){
                this.selectedStatus.push(this.statusTags[i].id)
              }
            }
          }else{
            this.selectedStatus.length = 0
          }
        }else{
          if(b){
            if(this.selectedStatus.indexOf(value) === -1){
              this.selectedStatus.push(value)
            }
          }else{
            let index = this.selectedStatus.indexOf(value)
            if(index !== -1){
              this.selectedStatus.splice(index,1)
            }
          }
        }
        console.log('selectedStatus',this.selectedStatus)
        const len = this.selectedStatus.length
        if(len === 0){
          this.queryParam.status = 0
          this.queryParam.statuses = []
        }else if(len === 1){
          if(this.selectedStatus[0] === 'total'){
            this.queryParam.status = 0
            this.queryParam.statuses = []
          }else{
            this.queryParam.status = this.selectedStatus[0]
          }
        }else{
          let arr = []
          for (let i = 0; i < len; i++) {
            if(this.selectedStatus[i] !== 'total'){
              arr.push(this.selectedStatus[i])
            }
          }
          this.queryParam.status = 0
          this.queryParam.statuses = arr
        }
        this.fetchList()
      },
      getDeviceTypeName(type){
        for (let i = 0; i < this.deviceTypeList.length; i++) {
          if(this.deviceTypeList[i].id === type){
            return this.deviceTypeList[i].name
          }
        }
        return ''
      },
      getZhibiaoName(type){
        let zhibiaos = ''
        for (let i = 0; i < this.deviceTypeList.length; i++) {
          if(this.deviceTypeList[i].id === type){
            zhibiaos = this.deviceTypeList[i].zhibiaoid
            break
          }
        }
        let arr = zhibiaos.split(',')
        let names = []
        for (let i = 0; i < arr.length; i++) {
          for (let j = 0; j < this.zhibiaoList.length; j++) {
            if(arr[i] == this.zhibiaoList[j].id){
              names.push(this.zhibiaoList[j].name)
              break
            }
          }
        }
        return names.join('、')
      },
      getGroupName(groupid){
        for (let i = 0; i < this.groupList.length; i++) {
          if(groupid === this.groupList[i].id){
            return this.groupList[i].name
          }
        }
        return ''
      },
      getMonitorName(mid){
        for (let i = 0; i < this.monitorList.length; i++) {
          if(mid === this.monitorList[i].id){
            return this.monitorList[i].name
          }
        }
        return ''
      },
      getWaterfactoryName(mid){
        let wfid = 0
        for (let i = 0; i < this.monitorList.length; i++) {
          if(mid === this.monitorList[i].id){
            wfid = this.monitorList[i].wfid
          }
        }
        for (let i = 0; i < this.waterfactoryList.length; i++) {
          if(wfid === this.waterfactoryList[i].id){
            return this.waterfactoryList[i].name
          }
        }
        return ''
      },
      handleDownload () {
        // console.log('handleDownload', this.selectedRowKeys)
        deviceExport(this.queryParam).then(data => {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', 'data-' + moment().format('YYYY-MM-DD') + '.xlsx')
          link.click()
        }).catch(err => {
          console.log(err)
        })
      },
      exportReport() {
        const params = Object.assign({}, this.queryParam)
        params.pageNum = 1
        params.pageSize = 1000000
        devicePage(params).then(res => {
          let list = res.data.list
          if(list){
            const len = list.length
            for (let i = 0;i < len;i++) {
              list[i].index = i + 1
              list[i].status = list[i].status === 1 ? '在线' : list[i].status === 2 ? '维护' : '故障'
              list[i].type = this.getDeviceTypeName(list[i].type)
              list[i].waterfactory = this.getWaterfactoryName(list[i].wfid)
              list[i].monitor = this.getMonitorName(list[i].mid)
              list[i].group = this.getGroupName(list[i].groupid)
              list[i].zhibiaos = list[i].zhibiaos.join("，")
              list[i].lonlat = list[i].lon ? (list[i].lon + ', ' + list[i].lat) : '-'
              list[i].equipTime = moment(list[i].equipTime).format('YYYY-MM-DD')
            }
            const tHeader = ['序号', '状态','设备名称', '设备编码', '设备类型', '水厂', '监测点', '监测管井', '监测指标','经纬度','安装时间']
            const filterVal = ['index', 'status','name', 'originid', 'type', 'waterfactory', 'monitor', 'group','zhibiaos', 'lonlat','equipTime']
            const cols = [{wpx:40},{wpx:60},{wpx:160},{wpx:80},{wpx:120},{wpx:150},{wpx:140},{wpx:110},{wpx:110},{wpx:120},{wpx:90}]
            const data = this.formatJson(filterVal, list)
            export_json_to_excel(tHeader, data, '设备列表',cols)
          }
          })
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]))
      },
      handleTableChange (pagination, filters, sorter) {
        console.log('Various parameters', pagination, filters, sorter)
        if (sorter) {
          let key = sorter.columnKey
          if (key === 'oldtypeName') {
            key = 'oldtype'
          }
          const order = sorter.order
          // if (order) {
          //   this.sorters[key] = sorter.order === 'descend' ? 'desc' : 'asc'
          // } else {
          //   delete this.sorters[key]
          // }
          // const arr = []
          // Object.keys(this.sorters).forEach(key => {
          //   arr.push(key + ' ' + this.sorters[key])
          // })
          // this.queryParam.orderBy = arr.join(',')
          if (order) {
            this.queryParam.orderBy = key + ' ' + (order === 'descend' ? 'desc' : 'asc')
          } else {
            this.queryParam.orderBy = ''
          }

          console.log('orderBy', this.queryParam.orderBy)
        }
        this.queryParam.pageNum = pagination.current
        this.queryParam.pageSize = pagination.pageSize
        this.fetchList()
        // }
      },
    }
  };
</script>

<style scoped lang="less">


</style>
